import React, { Component } from "react";

class Javascript extends Component {
  state = { class: "" };

  whatever = () => {};

  render() {
    return (
      <div className="">
        <p
          className={this.state.class}
          onClick={event => {
            this.setState({ class: "implode" });
            this.props.implode("javascript");
          }}
        >
          <svg
            className="javascriptLogo"
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2600 3090"
          >
            <path
              d="M1225 2995c-60-19-162-49-225-66s-207-58-320-90-245-69-295-82l-90-23-11-119c-23-232-84-914-109-1210-14-165-46-512-70-772-25-259-45-492-45-517V70h2623l-6 114c-3 62-13 181-22 262-22 209-92 981-105 1149-6 77-13 165-15 195-9 94-43 465-65 700-11 124-22 230-25 237-2 6-28 16-57 22-29 7-132 35-228 63s-233 66-305 85c-71 18-205 56-296 83-91 28-179 50-195 49-16 0-78-15-139-34zm43-1491l-3-859-127-3-128-3v681c0 374-3 680-6 680-4 0-112-29-240-65s-238-65-244-65-10 53-10 149c0 134 2 150 18 155 9 2 94 26 187 52 94 25 253 70 355 99s188 49 193 46c4-4 6-394 5-867zm707 744c110-28 202-52 203-54 5-3 71-838 67-842-2-2-116 10-254 27s-253 31-256 31-5-103-5-230V950h548l6-37c3-21 8-74 11-118s8-97 11-117l6-38h-832v1132l28-6c15-3 117-22 227-41 110-20 213-38 229-41l29-6-6 127c-3 70-8 129-10 132-3 2-114 33-247 69-133 35-245 66-248 68-4 2-6 71-4 154l3 150 147-39c81-22 237-62 347-91z"
              fill="#dab92d"
            />
            <g fill="#fed739">
              <path d="M1372 1558l3-1253 534-3 533-2-6 52c-3 29-42 460-86 958s-87 984-96 1080-19 178-23 182c-6 7-836 238-853 238-5 0-7-564-6-1252zm603 690c110-28 202-52 203-54 5-3 71-838 67-842-2-2-116 10-254 27s-253 31-256 31-5-103-5-230V950h548l6-37c3-21 8-74 11-118s8-97 11-117l6-38h-832v1132l28-6c15-3 117-22 227-41 110-20 213-38 229-41l29-6-6 127c-3 70-8 129-10 132-3 2-114 33-247 69-133 35-245 66-248 68-4 2-6 71-4 154l3 150 147-39c81-22 237-62 347-91zM210 1880c0-7 3-10 7-7 3 4 3 10 0 14-4 3-7 0-7-7zM200 1770c0-7 3-10 7-7 3 4 3 10 0 14-4 3-7 0-7-7z" />
            </g>
          </svg>
          <br />
          JAVASCRIPT
        </p>
      </div>
    );
  }
}

export default Javascript;
