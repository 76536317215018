import React, { Component } from "react";

class Loading extends Component {
  state = {};

  whatever = () => {};

  render() {
    return (
      <div class="loading">
        <h1>Loading ...</h1>
      </div>
    );
  }
}

export default Loading;
