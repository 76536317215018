import React, { Component } from "react";

class Footer extends Component {
  state = {};

  whatever = () => {};

  render() {
    return (
      <div className="footer">
        {/* {console.log()} */}
        Copyright © George Wong 2019
      </div>
    );
  }
}

export default Footer;
